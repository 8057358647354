.button {
    padding: 0.4rem 0.7rem;
    border-radius: 0.5rem;
    border: 1px solid #dfe3eb;
    cursor: pointer;
    font-size: 16px;
}

.button:not(.primaryButton) {
    background-color: #f0f4f6;
}

.button:not(.primaryButton):hover {
    background-color: #dfe3eb;
    box-shadow: inset 0 -12px 15px 0 #f0f4f6, 0 2px 8px 0 rgba(0, 0, 0, 0.03);
}

.primaryButton {
    color: white;
    background-color: #125f3f;
    background-image: linear-gradient(0deg, rgba(11, 66, 213, 0.4), rgba(36, 95, 255, 0) 75%);
}

.primaryButton:hover {
    background-color: #0b42d5;
    border: 1px solid #125f3f;
}