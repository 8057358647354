/* purgecss start ignore */

@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;
/* @fontsource/plus-jakarta-sans; */

/* Insights */

@font-face {
  font-family: "Jarkarta";
  src: url("/fonts/plus-jakarta-sans/PlusJakartaDisplay-Regular.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Jarkarta";
  src: url("/fonts/plus-jakarta-sans/PlusJakartaDisplay-Medium.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Jarkarta";
  src: url("/fonts/plus-jakarta-sans/PlusJakartaDisplay-Bold.otf");
  font-style: bold;
  font-weight: 600;
  font-display: swap;
}


#nprogress .bar {
  background: #125f3f !important;
}

.insights,
.useFont * {
  font-family: "Jarkarta" !important;
}

.navImage {
  min-height: 20rem !important;
  max-height: 20rem !important;
  overflow: hidden;
}

img {
  width: 100%;
height: auto;
height: 100%;
object-fit: cover;
object-position: center center;
}

#credential_picker_container {
  top: 60px !important;
}

@media (max-width: 640px) {
  .navImage {
    min-height: unset !important;
  }
  #credential_picker_container {
    top: unset !important;
  }
}

/* BELOW IS FOR IMAGE CROPPER */
img {
  display: block;
  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}

.imageBox > * {
  margin: 20px !important;
}

.navImage img {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.insights *,
.useFont * {
  transition: all 0.3s ease-out;
  transition-duration: 0.2s !important;
}
.insightItem {
  /* border: 1px solid darkgrey; */
}

.insightItem:hover {
  cursor: pointer;
}

.insightItem:hover .insightTitle {
  color: #125f3f !important;
}

.insightItem .navImage,
.insightItem .heroImg {
  overflow: hidden;
}

.insightItem:hover .navImage img,
.insightItem:hover .heroImg img {
  transform: scale(1.1);
}

.pageData p {
  font-size: 17px !important;
  line-height: 30px !important;
}

.forcePoppins * {
  font-family: "Poppins", sans-serif !important;
}

.customModalStyle {
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  top: 1rem;
  bottom: 1rem;
  width: 80%;
  margin: auto;
}

.ReactModal__Overlay {
  z-index: 16;
}

.customModalStyle > div:not(.modalSpinner),
.customModalStyle > div:not(.modalSpinner) > *:last-child {
  height: 100% !important;
}

/* somehow, the search bar is always included in the modal, something very awful */

.mobileModalButton,
.desktopBtn {
  color: teal !important;
  margin-bottom: 20px;
}

.mobileModalButton {
  display: none !important;
}

.desktopBtn {
  margin-left: auto !important;
  display: flex !important;
}

.loaderHome {
  margin-top: 20px;
  padding: 1rem;
}

@media (max-width: 1024px) {
  .customModalStyle {
    overflow: auto !important;
  }
  .mobileModalButton {
    display: block !important;
  }
  .desktopBtn {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .customModalStyle {
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    /* display: none !important; */
  }
  .copyBtn {
    font-size: 0.8rem !important;
    line-height: 1.4 !important;
  }
  .similarListings {
    display: none !important;
  }
  .loaderHome {
    margin-top: 60px;
    flex-direction: column;
  }
  .loaderHome > * {
    width: 100% !important;
  }
  .trustedCompanies > .remove {
    display: none;
  }
}